<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.campaigns')"
    />

    <DataTable
      :headers="headers"
      :items="data"
      :preloader="fetchinData"
      :edit="false"
      :watch="true"
      :trash="false"
      :copy="false"
      :leads="false"
      :preview="false"
      :stats="false"
      @tableEvent="emittedTableEvent"
    ></DataTable>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import DataTable from "@/components/ui/DataTable";

export default {
  name: "Campaigns",

  components: {
    PageHeader,
    DataTable,
  },

  data() {
    return {
      pageTitle: "mitfitLEADS",
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      data: [], // wrapper for fetched data
      headers: [],
      dialog: false,
      componentKey: 0,
      currentTimestamp: Math.floor(Date.now() / 1000),
    };
  },

  props: [],

  created: function () {
    // is a company changer allowed?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        "companies/" + this.companyID + "/campaigns"
      );
      this.fetchinData = false;
      this.data = response.data.data.campaign;

      this.headers = [
        {
          text: this.$t("status"),
          value: "cmpStatus",
          sortable: false,
          width: "30px",
        },
        { text: this.$t("campaign"), sortable: false, value: "page_title" },
        { text: "TYP", value: "campaignType" },
        { text: this.$t("runtime"), value: "runtimePeriod" },

        {
          text: this.$t("participants"),
          sortable: false,
          value: "memberCount",
          width: "30px",
        },
        /* { text: this.$t("version"), value: "version", width: "30px" }, */
        { text: "", value: "actions", sortable: false, width: "30px" },
      ];
    },

    emittedTableEvent(obj) {
      if (obj.action === "watchItem") {
        /* Hier checke ich die Version der Kampagnen: 1 = alte Kampagne, 2 = neue Kampagne in Manage */
        const version = obj.itemData.version;
        const hasMitfitPay = obj.itemData.hasMitfitPay;
        if (version === 1) {
          this.$router.push({
            name: "ocampaigns:id",
            params: { id: obj.itemId },
          });
        } else if (version === 2) {
          if (hasMitfitPay === "1") {
            this.$router.push({
              name: "pay-campaigns:id",
              params: { id: obj.itemId },
            });
          } else {
            this.$router.push({
              name: "campaigns:id",
              params: { id: obj.itemId },
            });
          }
        }
      }
    },
  },

  computed: {},

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
